import React from "react"
import Hero from "../components/landing-pages/Rm/hero"
import PdfSoftware from "../components/landing-pages/Rm/pdfSoftware"
import Reviews from "../components/landing-pages/Rm/reviews"
import styled from "styled-components"
import HeaderLp from "../components/landing-pages/Rm/headerLp"
import Seo from "../components/seo"
const Main = styled.div`
  overflow: hidden;
  width: 100vw;
`

function Rm({ location }) {
  return (
    <Main>
      <Seo title="Create &amp; Edit PDF files with PDF Pro" noindex={true} />
      <HeaderLp wrapLocation={location} location={`rm`} closedSticky={true} />
      <Hero />
      <PdfSoftware />
      <Reviews />
    </Main>
  )
}

export default Rm
