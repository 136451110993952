import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "../../layout.css"
import { AlignContent } from "../../shared/container.style"

import Create from "../../../icons/Lp3/create-convert.svg"
import Edit1 from "../../../icons/Lp3/edit-text1.svg"
import Edit2 from "../../../icons/Lp3/edit-text2.svg"
import Merge from "../../../icons/Lp3/merge-split.svg"
import Fill from "../../../icons/Lp3/fill-sign.svg"
import Modify from "../../../icons/Lp3/modify-pages.svg"
import Ocr from "../../../icons/Lp3/use-ocr.svg"
import Comments from "../../../icons/Lp3/links-comments.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  width: 100%;
  height: max-content;
`

const PositionContainer = styled(AlignContent)`
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
  }
`
const ViewCompleteFeatureSet = styled.p`
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: 3.5rem;
`

const MainHeader = styled.h2`
  text-align: center;
  margin: 0 0 72px 0;
  /* padding-top: 3.5rem; */
  font-family: sans-serif;
  font-style: normal;
  font-weight: 1400px;
  font-size: 32px;
  line-height: 48px;
  color: #111111;
  @media (max-width: 480px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    font-size: 24px;
  }
  text-align: center;
`

const PdfSofttwareStyle = styled.section`
  font-family: "Work Sans", sans-serif;
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
`

const RedTitle = styled.div`
  font-size: 12px;

  color: #e05f46;
  width: 100%;
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
  padding-top: 128px;
`
const RowVector = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  flex-wrap: wrap;
  @media (max-width: 810px) {
    justify-content: space-around;
  }

  @media (max-width: 375px) {
    flex-direction: column;
  }
`

const RowVectorShort = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  margin-top: 32px;
  flex-wrap: wrap;
  @media (max-width: 375px) {
    flex-direction: column;
    margin-top: 0;
  }
  margin-bottom: 128px;
`
const VectorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  overflow: visible;
  .back {
    position: relative;
    left: -18px;
    top: -74px;
    transform: scale(0.6);
  }
  .front {
    transform: translate(12px, 10px) scale(0.6);
  }
  svg {
    width: 100%;
    height: 100%;
    fill: black;
    stroke: black;
    /* filter: grayscale(100%) contrast(500%); */
  }
`
const SvgVector = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  overflow: visible;
`
const PdfSoftware = () => {
  return (
    <Container>
      <PositionContainer>
        <RedTitle id="features">FEATURES</RedTitle>
        <MainHeader>
          All the Features you need at an <br /> Unbeatable Price
        </MainHeader>
        <PdfSofttwareStyle>
          <RowVector>
            <VectorBox>
              <SvgVector>
                <Create />
              </SvgVector>
              <h5>Create &amp; Convert</h5>
            </VectorBox>
            <VectorBox>
              <SvgVector>
                <Edit1 className="front" />
                <Edit2 className="back" />
              </SvgVector>
              <h5>Edit Text &amp; Images</h5>
            </VectorBox>
            <VectorBox>
              <SvgVector>
                <Merge />
              </SvgVector>
              <h5>Merge &amp; Split PDFs</h5>
            </VectorBox>
            <VectorBox>
              <SvgVector>
                <Fill />
              </SvgVector>
              <h5>Fill &amp; Sign PDFs</h5>
            </VectorBox>
          </RowVector>
          <RowVectorShort>
            <VectorBox>
              <SvgVector>
                <Modify />
              </SvgVector>
              <h5>Modify Pages</h5>
            </VectorBox>
            <VectorBox>
              <SvgVector>
                <Ocr />
              </SvgVector>
              <h5>Use OCR</h5>
            </VectorBox>
            <VectorBox>
              <SvgVector>
                <Comments />
              </SvgVector>
              <h5>Add links/comments</h5>
            </VectorBox>
          </RowVectorShort>
        </PdfSofttwareStyle>
      </PositionContainer>
    </Container>
  )
}

export default PdfSoftware
