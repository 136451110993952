import styled from "styled-components"

export const AlignContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  height: max-content;
  z-index: 1;

  @media (max-width: 767px) {
    width: 95%;
    flex-direction: column;
  }
`
