import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "../../layout.css"
import { AlignContent } from "../../shared/container.style"
import { navigate } from "gatsby"

import Window from "../../../icons/window.svg"
import Star from "../../../icons/Lp3/star.svg"

import back from "../../../images/Lp3/Group.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fbfbfb;
  background: #f6f6f7;
  width: 100vw;
  height: max-content;

  background-size: 100% 100%;

  @media (max-width: 980px) {
    background-size: cover;
  }
`

const PositionContainer = styled(AlignContent)`
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 96px 0;
  /* flex-wrap: wrap; */
  @media (max-width: 980px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    align-items: center;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 675px) {
    align-items: center;
  }
  @media (max-width: 400px) {
    align-items: flex-start;
  }
  margin-bottom: 24px;
`

const BuyButton = styled.button`
  width: 230px;
  height: 80px;
  background: #00cd61;
  box-shadow: 0px 20px 12px -12px rgba(27, 223, 120, 0.316461);
  border-radius: 40px;
  border: none;
  color: white;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  /* margin-top: 20px; */
  letter-spacing: 0.96px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5%;
  }

  @media (max-width: 768px) {
    font-size: 14.4px;
    line-height: 18px;
    width: 184px;
    height: 64px;
  }
`

const CtaTitle = styled.div`
  font-size: 30px;
  line-height: 34px;
  max-width: 215px;
  font-weight: 800;
  margin-bottom: 48px;
  margin-top: 24px;

  @media (max-width: 980px) {
    font-size: 24px;
    line-height: 28px;
  }
`
const RedTitle = styled.div`
  font-size: 12px;
  color: #e05f46;
  width: 100%;
  font-weight: 600;
  font-family: sans-serif;
  text-align: left;
  margin-top: 24px;
`

const Cta = styled.div`
  /* width: 26.5%; */

  @media (max-width: 400px) {
    /* width: 40%; */
  }
`

const Card = styled.div`
  width: 320px;
  /* min-width: 320px; */
  margin: 0 21px;
  padding: 0 38px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  @media (max-width: 580px) {
    padding: 64px 38px;
    margin: auto;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
`
const CardSmall = styled.div`
  width: 320px;
  /* height: */
  margin: 0 21px;
  margin-top: 84px;
  padding: 0 38px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  transform: translateY(24px);
  @media (max-width: 580px) {
    padding: 64px 38px;
    margin: auto;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`

const CardTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`

const CardParagraph = styled.div`
  font-size: 12px;
  font-size: 16px;

  margin: 22px 0;
  font-weight: bold;
`
const QuoteTags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 76px;
  min-width: 76px;
  margin-right: 34px;

  @media (max-width: 400px) {
    width: 50px;
    min-width: 50px;
    margin-right: 24px;
    margin-left: 8px;

    min-height: 396px;
  }
`
const Quote = styled.div`
  width: 28px;
  height: 58px;
  background: #ddddde;
  border-bottom-left-radius: 32px;

  @media (max-width: 400px) {
    width: 20px;
    height: 42px;
  }
`
const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  /* height: 16px; */
  width: max-content;
  svg {
    width: 24px;
    margin-right: 4px;
  }
`
const CtaHolder = styled.div`
  width: 34%;
  display: flex;
  flex-direction: row;
  margin-right: 32px;
  @media (max-width: 980px) {
    margin: auto;
    min-width: 296px;
  }
  @media (max-width: 400px) {
    margin: auto;
    width: 90%;
    min-width: 1000;
  }
`
const CardHolder = styled.div`
  width: 66%;
  display: flex;
  flex-direction: row;
  @media (max-width: 980px) {
    justify-content: center;
    width: 100%;
    min-height: 396px;
    margin-top: 48px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 396px;
  }
`
const Reviews = ({ location }) => {
  const [lpType, setLpType] = useState("")

  useEffect(() => {
    if (window.location.href.includes("rm")) setLpType("rm")
  }, [])

  return (
    <Container style={{ backgroundImage: `url(${back})` }}>
      <PositionContainer>
        <CtaHolder>
          <QuoteTags>
            <Quote />
            <Quote />
          </QuoteTags>
          <Cta>
            <RedTitle id="reviews">REVIEWS</RedTitle>
            <CtaTitle>
              Ready to join millions of satisfied users who trust PDF Pro?
            </CtaTitle>
            <ButtonHolder>
              <BuyButton onClick={() => navigate(`/${lpType}/checkout`)}>
                Buy Now
                <Window />
              </BuyButton>
            </ButtonHolder>
          </Cta>
        </CtaHolder>
        <CardHolder>
          <Card>
            <CardTitle>PDF PRO</CardTitle>
            <CardParagraph>
              I like the simplicity and design of the software. I like that you
              can import images files, which is huge for me. Most of all, the
              product support team is top notch
            </CardParagraph>
            <CardRow>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </CardRow>
          </Card>
          <CardSmall>
            <CardTitle>Great Value</CardTitle>
            <CardParagraph>
              Clean, easy to use, mark up/ stamp/ editing and very competitive
              pricing.
            </CardParagraph>
            <CardRow>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </CardRow>
          </CardSmall>
        </CardHolder>
      </PositionContainer>
    </Container>
  )
}

export default Reviews
