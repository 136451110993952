import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Main from "../styles/mainStyle"
import MainWrapper from "../styles/mainWrapperStyle"
import { navigate } from "gatsby"
import { useState, useEffect } from "react"
import Window from "../../../icons/window.svg"
import Edit from "../../../icons/IconEdit1.svg"
import Sign from "../../../icons/IconSign1.svg"
import Merge from "../../../icons/IconMerge1.svg"
import hero from "../../../images/Lp3/background.png"

const MainStyle = styled(Main)`
  background: #f6f6f7;
  background-size: 100% 100%;

  overflow-y: visible;
  width: 100vw;

  @media (max-width: 678px) {
    background-size: cover;
  }

  .gatsby-image-wrapper,
  .gatsby-image-wrapper-constrained {
    transition: all 1s;

    img {
      width: 130%;
      max-height: 610px;
      max-width: 610px;
      transition: all 1s;
    }
    div {
      width: 130%;
      overflow: visible !important;
      max-height: 610px;
      max-width: 610px;
      transition: all 1s;
    }
    overflow: visible !important;
    max-height: 610px;
    max-width: 610px;
    /* width: 800px !important; */
    transform: translate(0, 100px);

    @media (max-width: 980px) {
      transform: translateY(188px);

      img,
      div {
        width: 160%;
      }
    }

    @media (max-width: 768px) {
      transform: translateY(148px);
      img,
      div {
        width: 140%;
      }
    }

    @media (max-width: 668px) {
      transform: translateY(0);
      margin-top: 64px;

      img,
      div {
        width: 100%;
      }
    }
  }
`
const LastChanceBody = styled.div`
  background: #db462a;
  font-size: 12px;
  line-height: 12px;
  color: white;
  padding: 6px 12px;
  padding-left: 18px;
  width: max-content;
  font-family: sans-serif;
  font-weight: bold !important;
`
const BannerRow = styled.div`
  display: flex;
  flex-direction: row;
`

const LastChance = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateX(-16px);
  margin-bottom: 18px;

  @media (max-width: 768px) {
    transform: translateX(0);

    margin-right: auto;
  }
`
const LastChanceHead = styled.div`
  width: max-content;
  border: 12px solid #db462a;
  border-right: 12px solid transparent;
`

const LastChanceTail = styled.div`
  width: max-content;
  border: 6px solid #cd2f2b;
  border-right: 8px solid #cd2f2b;

  border-bottom: 6px solid transparent;
  border-left: 8px solid transparent;
`
const ImageWrapper = styled.div`
  max-height: 580px;
  overflow: visible;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const HeroWrapper = styled(MainWrapper)`
  @media (max-width: 675px) {
    flex-wrap: wrap;
  }
  max-width: 1100px;

  margin-top: 0%;
  margin-bottom: 0%;

  @media (max-width: 1030px) {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  overflow: visible;
`
const CTA = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 54%;
  @media (max-width: 675px) {
    flex-wrap: wrap;
    flex-basis: 100%;
    order: 0;
    align-items: center;
    text-align: center;
  }
`
const Title = styled.h1`
  font-size: 58px;
  line-height: 60px;
  font-family: sans-serif;
  font-weight: 1400;
  max-width: 700px;
  margin-bottom: 0;
  @media (max-width: 1100px) {
    font-size: 50px;
    line-height: 54px;
  }

  @media (max-width: 768px) {
    font-size: 42.4px;
    line-height: 48px;
  }

  @media (max-width: 590px) {
    font-size: 40px;
    line-height: 42px;
  }
`
const Slogan = styled.div`
  font-size: 42px;
  line-height: 60px;
  font-family: sans-serif;

  font-weight: bold;
  strike {
    font-weight: 1400;
  }
  b {
    color: #cd2f2b;
    font-size: 52px;

    @media (max-width: 1100px) {
      font-size: 46px;
      line-height: 54px;
    }

    @media (max-width: 768px) {
      font-size: 31px;
      line-height: 48px;
    }
  }

  @media (max-width: 1100px) {
    font-size: 36px;
    line-height: 54px;
  }

  @media (max-width: 768px) {
    font-size: 21.6px;
    line-height: 48px;
  }

  @media (max-width: 590px) {
    font-size: 20px;
    line-height: 42px;
  }
`

const BuyButton = styled.button`
  width: 230px;
  height: 80px;
  background: #00cd61;
  box-shadow: 0px 20px 12px -12px rgba(27, 223, 120, 0.316461);
  border-radius: 40px;
  border: none;
  color: white;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  letter-spacing: 0.96px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5%;
  }

  @media (max-width: 768px) {
    font-size: 14.4px;
    line-height: 18px;
    width: 184px;
    height: 64px;
  }
`
const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 675px) {
    align-items: center;
  }
`
const FeatureBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    margin: auto;
    margin-bottom: 16px;
  }
`
const SvgWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  height: auto;
`
const FeatureLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  width: 84%;
  @media (max-width: 980px) {
    flex-direction: column;
    margin-top: 32px;
  }
  @media (max-width: 668px) {
    flex-direction: row;
    margin-top: 32px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 32px;
    flex-wrap: wrap;
  }
`
const ButtonUnderText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #4e526b;
  margin-top: 5%;

  text-align: left;
  @media (max-width: 675px) {
    text-align: center;
  }
`

function Hero({ location }) {
  const [lpType, setLpType] = useState("")

  useEffect(() => {
    if (window.location.href.includes("rm")) setLpType("rm")
  }, [])

  return (
    <MainStyle style={{ backgroundImage: `url(${hero})` }}>
      <HeroWrapper>
        <CTA>
          <LastChance>
            <BannerRow>
              <LastChanceBody>LAST CHANCE OFFER</LastChanceBody>
              <LastChanceHead />
            </BannerRow>
            <LastChanceTail />
          </LastChance>
          <Title>Get PDF Pro for</Title>
          <Slogan>
            as low as <strike>8$</strike> <b>$3/month!</b>
          </Slogan>
          <ButtonHolder>
            <BuyButton onClick={() => navigate(`/${lpType}/checkout`)}>
              Buy Now
              <Window />
            </BuyButton>
            {/* <ButtonUnderText>Compatible with Windows 11/10/8/7</ButtonUnderText> */}
          </ButtonHolder>
          <FeatureLine>
            <FeatureBox>
              <SvgWrapper>
                <Edit />
              </SvgWrapper>
              <h5>
                Edit text &amp; <br /> images
              </h5>
            </FeatureBox>
            <FeatureBox>
              <SvgWrapper>
                <Sign />
              </SvgWrapper>
              <h5>
                Fill &amp; sign any <br /> PDF file
              </h5>
            </FeatureBox>
            <FeatureBox>
              <SvgWrapper>
                <Merge />
              </SvgWrapper>
              <h5>
                Create &amp; <br /> convert
              </h5>
            </FeatureBox>
          </FeatureLine>
        </CTA>
        <ImageWrapper>
          <StaticImage
            src="../../../images/Lp3/Box.png"
            quality={60}
            loading="eager"
            alt="PDFPRO box"
          />
        </ImageWrapper>
      </HeroWrapper>
    </MainStyle>
  )
}

export default Hero
