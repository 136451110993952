import React from "react"
import styled from "styled-components"
import Logo from "../../../icons/PDF-logo-vector.svg"
import { Link, navigate } from "gatsby"
import Hamburger from "../../../icons/hamburger_menu.svg"
import Close from "../../../icons/close-icon-popup.svg"
import { useState, useEffect } from "react"
import { globalHistory } from "@reach/router"

const BackgroundFiller = styled.div`
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  height: 71px;
`
const HeaderS = styled.div`
  width: 80%;
  min-height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 9;
  max-width: 1100px;
  ${props =>
    props.maxW == "lp1" ? `max-width: 1100px;` : `max-width: 1100px;`}
  margin: auto;
  svg {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    svg {
      position: absolute;
      margin-top: 21px;
    }
    align-items: flex-start;
  }
`

const HeaderLimit = styled.div`
  width: 100%;
  background: white;
  position: fixed;
  ${props => (props.closedSticky == false ? `top: 41px;` : `top: 0;`)}
  z-index: 1000;
  border-bottom: 1px solid #e1e1e1;
`

const NavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  width: 100%;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    padding: 70px 0;
  }
`

const NavBarMobile = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  a {
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;
    margin-left: 5%;
    display: flex;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: ${props => (props.open ? `70px 0;` : `0`)};
  }
`

const Bread = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 28px;
  }
  @media (max-width: 768px) {
    margin-top: 21px;
    display: flex;
  }
`

const Button = styled.div`
  font-size: 14px;
  color: #db462a;
  border: 2px solid #db462a;
  padding: 6px 48px;
  border-radius: 32px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 5%;
  :hover {
    background: #db462a;
    color: white;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`
function HeaderLp({ wrapLocation, closedSticky, location }) {
  const [open, setOpen] = useState(false)
  const [lpType, setLpType] = useState(location)
  const [param, setParam] = useState("")

  const [navVisible, setNavVisible] = useState(true)

  /// Detects URL change
  const [loc, setLoc] = useState(null)
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action == "PUSH" || action == "REPLACE" || action == "POP")
        setLoc(loc => (loc = !loc))
    })
  }, [setLoc])

  useEffect(() => {
    if (window.location.href.includes("lp1")) setLpType("lp1")
    if (window.location.href.includes("lp2")) setLpType("lp2")
    if (window.location.href.includes("rm")) setLpType("lp2")

    if (window.location.href.includes("checkout")) {
      setNavVisible(false)
    } else {
      setNavVisible(true)
    }

    setParam(window.location.search)
  }, [loc])

  return (
    <BackgroundFiller>
      <HeaderLimit closedSticky={closedSticky}>
        <HeaderS maxW={lpType}>
          <Logo />
          {navVisible && (
            <NavBar>
              <Link to={`/${wrapLocation.pathname.split("/")[1]}/#features`}>
                Features
              </Link>
              <Link to={`/${wrapLocation.pathname.split("/")[1]}/#reviews`}>
                Reviews
              </Link>

              <Button onClick={() => navigate("/rm/checkout")}>Buy Now</Button>
            </NavBar>
          )}
          {navVisible && (
            <NavBarMobile open={open}>
              {open && (
                <>
                  <Link
                    to={`/${wrapLocation.pathname.split("/")[1]}/#features`}
                  >
                    Features
                  </Link>
                  <Link to={`/${wrapLocation.pathname.split("/")[1]}/#reviews`}>
                    Reviews
                  </Link>
                  <Button onClick={() => navigate("/rm/checkout")}>
                    Buy Now
                  </Button>
                </>
              )}
            </NavBarMobile>
          )}
          {navVisible && (
            <Bread>
              {!open && (
                <Hamburger onClick={() => setOpen(open => (open = !open))} />
              )}
              {open && (
                <Close onClick={() => setOpen(open => (open = !open))} />
              )}
            </Bread>
          )}
        </HeaderS>
      </HeaderLimit>
    </BackgroundFiller>
  )
}

export default HeaderLp
